/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import { CollectionTemplateType, ProductItemType } from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'

type Props = CollectionTemplateType

const CollectionBespoke: FC<Props> = ({ pageContext: { data } }) => {
  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      seoDescription={data.seo?.description || data.description}
      description={data.description}
      handle={data.handle}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      catalog={data.catalog}
      headerDescription={data.metafields?.headerDescription}
      featuredCollectionsList={data.featuredCollections}
      templateKey="bespoke"
    >
      <CollectionProducts
        collectionHandle={data.handle}
        initialData={data.products as ProductItemType[]}
        pagination={data.pagination}
        data={data}
        gridVariant="layout.template-furniture"
        template="bespoke"
      />
    </CollectionLayout>
  )
}

export default CollectionBespoke
